
<script>
export default {
  data() {
    return { weekObject: { mo: 1, tu: 2, we: 3, th: 4, fr: 5, sa: 6, su: 0 } };
  },
};







// function parsesScheduler(fieldsObject, data) {
//   var $fieldsObject = $(fieldsObject);
//   var daily = [];
//   var weekly = { mo: [], tu: [], we: [], th: [], fr: [], sa: [], su: [] };
//   $fieldsObject
//     .find(".cronTimesWrapper")
//     .find(".cronSelector")
//     .each(function () {
//       var $cronSelector = $(this);
//       var cronObject = $cronSelector.data("cronObject");

//       var value = cronObject.cron("value");
//       if (typeof value != "undefined") {
//         var valueArray = value.split(" ");
//         var dayNumber = valueArray[4];
//         //console.log(valueArray,value,dayNumber);
//         if (valueArray[4] == "*") {
//           //daily
//           valueString = getStringFromCron(valueArray);
//           daily.push(valueString);
//         } else {
//           weekObjectReversed = array_flip(window.weekObject);
//           valueString = getStringFromCron(valueArray);

//           var day = weekObjectReversed[dayNumber];
//           // console.log(dayNumber,day,weekly);
//           weekly[day].push(valueString);
//           //  console.log(valueString);
//         }
//       }
//     });
//   //console.log(daily);
//   //console.log(weekly);
//   var cronObject = { daily: daily, weekly: weekly };
//   //console.log(cronObject);
//   data["c_obj"] = cronObject;
//   return data;
// }



// function removecronLine(caller) {
//   var $caller = $(caller);
//   var length = $caller.closest(".cronTimesWrapper").find(".cronselectoLine")
//     .length;
//   if (length > 1) {
//     $caller.closest("div").remove();
//   } else {
//     $caller
//       .attr("title", "at least one entry is required")
//       .tooltip({
//         close: function (event, ui) {
//           $caller.data("tooltip", false);
//           $caller.attr("focused", 0);
//           //$(that).tooltip( "destroy" );

//           $caller.tooltip().tooltip("destroy");
//         },
//       })
//       .tooltip("open");
//   }
// }

// function deleteMultipleRowSelected() {
//   selected = [];
//   $(".selectedElements:checked").each(function () {
//     selected.push([$(this).val(), $(this).attr("keyvalue")]);
//   });
//   if (selected.length > 0) {
//     $(".confirmationDialog").html("Are you sure you want to delete?");
//     $(".confirmationDialog").dialog({
//       autoOpen: false,
//       modal: true,
//       open: function (event) {
//         let $dialogContainer = $(event.target)
//           .closest(".ui-dialog")
//           .find(".ui-dialog-buttonset");
//         $dialogContainer.find(".ui-button:first").addClass("iconDelete");
//         $dialogContainer.find(".ui-button").eq(1).addClass("iconCancel");
//         //$dialogContainer.find(".ui-button").removeClass('ui-button');// for design adjustment
//       },
//       buttons: {
//         Delete: function () {
//           sendTableRowDelete(selected);
//           $(this).dialog("close");
//         },
//         Cancel: function () {
//           $(this).dialog("close");
//         },
//       },
//     });

//     $(".confirmationDialog").dialog("open");
//   } else {
//     showWarn();
//   }
// }

// function sendTableRowDelete(selected) {
//   $.ajax({
//     type: "POST",
//     data: {
//       function: "deleteMultipleTestcaseRow",
//       sel: selected,
//       requestType: "ajax",
//     },
//     dataType: "json",
//     success: function (data) {
//       checkLegalToDeleteTestcaseRow(data); //if not trigger popup
//     },
//     error: function (jqXHR, textStatus, errorThrown) {
//       $dialog = getActiveDialogDiv(false);
//       $dialog.html(jqXHR.responseText);
//       var dialogOptions = { modal: true, width: 500 };
//       generateDialog($dialog, dialogOptions, "");
//     },
//   });
// }

// function checkLegalToDeleteTestcaseRow(data) {
//   if (
//     typeof data.result.rows != "undefined" ||
//     typeof data.rows != "undefined"
//   ) {
//     // testcase not to delete
//     var $messageDialog = $("#messageDialog");
//     var options = {
//       modal: true,
//       width: "650",
//       height: "350",
//       title: "Notice",
//     };
//     $messageDialog.html(renderDeletedTestcaseRow(data));
//     $messageDialog.dialog(options, "title", "Notice");
//   } else {
//     //Testcase can to delete
//     renderDeletedTestcaseRow(data);
//   }
// }

// function renderDeletedTestcaseRow(data) {
//   var $returnedDiv = $("<div></div>");
//   if (typeof data.result.rows != "undefined") {
//     // for single Test case to Delete
//     var list = data.result.rows;
//     $returnedDiv.append(data.result.message);
//   }
//   if (typeof data.rows != "undefined") {
//     //for Multiple
//     var list = data.rows;
//     $returnedDiv.append(data.message);
//   }
//   var nameList;
//   if (typeof list != "undefined") {
//     // List in Pop up
//     $.each(list, function (index, value) {
//       if (typeof value.c_name != "undefined") {
//         var $usedList = $("<ul></ul>").appendTo($returnedDiv);
//         nameList =
//           "<span style='color:red;'> Testcase:</span> " +
//           value.tcName +
//           " <span style='color:red;'> Scheduled with </span> " +
//           value.c_name;
//         var $link = $("<li>" + nameList + "</li>").appendTo($usedList);
//       }
//     });
//   }
//   if (typeof data.canDelete != "undefined") {
//     var nameList;
//     $.each(data.canDelete, function (index, value) {
//       if (typeof value != "undefined") {
//         var $tr = $("#tableRow" + value);
//         deleteViewRow($tr);
//       }
//     });
//   }
//   return $returnedDiv;
// }

// function deleteRowClicked(n_id, n_id_key, caller) {
//   $(".confirmationDialog").html("Are you sure you want to delete?");
//   $(".confirmationDialog").dialog({
//     autoOpen: false,
//     modal: true,
//     open: function (event) {
//       let $dialogContainer = $(event.target)
//         .closest(".ui-dialog")
//         .find(".ui-dialog-buttonset");
//       $dialogContainer.find(".ui-button:first").addClass("iconDelete");
//       $dialogContainer.find(".ui-button").eq(1).addClass("iconCancel");
//       // $dialogContainer.find(".ui-button").removeClass('ui-button');// for design adjustment
//     },
//     buttons: {
//       Delete: function () {
//         deleteTestcase(n_id, n_id_key, caller);
//         $(this).dialog("close");
//       },
//       Cancel: function () {
//         $(this).dialog("close");
//       },
//     },
//   });
//   $(".confirmationDialog").dialog("open");
// }

// function deleteTestcase(n_id, n_id_key, caller) {
//   $.ajax({
//     type: "POST",
//     data: {
//       function: "delete",
//       n_id: n_id,
//       n_id_key: n_id_key,
//       requestType: "ajax",
//     },
//     dataType: "json",
//     success: function (data, textStatus) {
//       removeDeletedTestcaseRow("", data, textStatus, "", "", n_id_key);
//     },
//     error: function (jqXHR, textStatus, errorThrown) {
//       $dialog = getActiveDialogDiv(false);
//       $dialog.html(jqXHR.responseText);
//       var dialogOptions = { modal: true, width: 500 };
//       generateDialog($dialog, dialogOptions, "");
//     },
//   });
// }

// function removeDeletedTestcaseRow(
//   $form,
//   data,
//   statusText,
//   xhr,
//   editDiv,
//   n_id_key
// ) {
//   var $messageDialog = $("#messageDialog");
//   var n_id = data.n_id;
//   if (data.deleted == "true") {
//     var $tr = $("#tableRow" + n_id);
//     deleteViewRow($tr);
//     deleteCloudObject(n_id, n_id_key);
//   } else if (data.result.result == "illegal-tc") {
//     $messageDialog.html(renderDeletedTestcaseRow(data));
//   } else {
//     $messageDialog.html("<div>failed to remove " + n_id + ".</div>");
//   }
//   if (data.deleted != "true") {
//     var options;
//     if (typeof data.function != "undefined" && data.function == "wipe") {
//       options = {
//         modal: true,
//         width: "350",
//         height: "150",
//       };
//     } else {
//       options = {
//         modal: true,
//         width: "650",
//         height: "350",
//         title: "Notice",
//       };
//     }
//     $messageDialog.dialog(options);
//   }
// }

// function deleteCloudObject(n_id, n_id_key) {
//   $.ajax({
//     type: "POST",
//     data: {
//       function: "deleteCloudObj",
//       n_id: n_id,
//       n_id_key: n_id_key,
//       requestType: "ajax",
//     },
//     dataType: "json",
//     success: function () {},
//   });
// }
</script>